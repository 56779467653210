<template>
  <div>
    <h3>Pages</h3>
    <v-btn
      text
      @click="showDialog"
      outlined
      class="btn btn-lg btn-add-main"
      style="background: transparent"
    >
      <i class="fas fa-plus-circle"></i> Add Page
    </v-btn>

    <v-btn
      text
      @click="showBlogDialog"
      outlined
      class="btn btn-lg btn-add-main mr-2"
      style="background: transparent"
    >
      <i class="fas fa-plus-circle"></i> Add Blog Page
    </v-btn>
    <v-btn v-if="pages.length<1"
      text
      @click="reImportPages"
      outlined
      class="btn btn-lg btn-primary mr-2"
      style="background: transparent"
    >
      <i class="fas fa-undo"></i> Re-import Page
    </v-btn>
    <v-btn
      text
      :to="{ name: 'manage-websites', params: { domainname: siteUrl } }"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background: transparent"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{ siteUrl }}
    </span>
    <v-app>
      <div class="row mt-2" >
        <div class="col-xl-12">
          <KTPortlet v-bind:title="''">
            <template v-slot:body>
              <div class="row" v-if="loading">
                <div class="col-md-12">
                  <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
              </div>
              <div class="row" v-else-if="!loading && pages && pages.length<1">
                <div class="col-md-12 text-center">
                  <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                  <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-6">
                  <p>
                    Filter
                    <a @click="getPages(null, 'active')">Active ({{ activePages }})</a>|
                    <a @click="getPages(null, 'trash')">Trash ({{ trashPages }})</a>|
                    <a @click="getPages(null, 'all')">All ({{ allPages }})</a>
                  </p>
                </div>
                <b-table
                  hover
                  responsive
                  :items="pages"
                  id="my-table"
                  :busy.sync="isBusy"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading Pages...</strong>
                    </div>
                  </template>
                  <template v-slot:cell(is_active)="data">
                    <i
                      v-if="data.item.is_active"
                      class="kt-nav__link-icon flaticon2-check-mark text-success"
                    ></i>

                    <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                  </template>
                  <template v-slot:cell(seo_ready)="data">
                    <i
                      v-if="data.item.seo_ready"
                      class="kt-nav__link-icon flaticon2-check-mark text-success"
                    ></i>
                    <i v-else class="kt-nav__link-icon flaticon2-warning text-danger"></i>
                    Fix SEO settings
                  </template>
                  <!-- A virtual composite column -->
                  <template v-slot:cell(action)="data">
                    <div class="kt-widget__toolbar" v-if="!data.item.deleted_at">
                      <a
                        href="#"
                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                        data-toggle="dropdown"
                      >
                        <i class="flaticon-more-1"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                        <ul class="kt-nav">
                          <li class="kt-nav__item">
                            <a href="#" @click="editPage(data.item.id)" class="kt-nav__link">
                              <i class="kt-nav__link-icon flaticon-edit"></i>
                              <span class="kt-nav__link-text">Edit</span>
                            </a>
                          </li>

                          <li class="kt-nav__item">
                            <a href="#" @click="editPageSlug(data.item)" class="kt-nav__link">
                              <i class="kt-nav__link-icon flaticon-file"></i>
                              <span class="kt-nav__link-text">Edit Page URL</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a
                                :href="'http://' + siteUrl + '/' + data.item.slug"
                                class="kt-nav__link"
                                target="_blank"
                            >
                              <i class="kt-nav__link-icon flaticon-eye"></i>
                              <span class="kt-nav__link-text">Preview</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <router-link
                              class="kt-nav__link"
                              :to="{
                                name: 'manage-seo',
                                params: { domainname: siteUrl,page:data.item.slug },
                              }"
                            >
                              <i class="kt-nav__link-icon flaticon-diagram"></i>
                              <span class="kt-nav__link-text">Manage SEO</span>
                            </router-link>
                          </li>
                          <!-- <li class="kt-nav__item">
                                                    <a href="#" class="kt-nav__link">
                                                      <i class="kt-nav__link-icon flaticon2-settings"></i>
                                                      <span class="kt-nav__link-text">Settings</span>
                                                    </a>
                          </li>-->
                          <li class="kt-nav__item">
                            <a href="#" @click="subheader(data.item)" class="kt-nav__link">
                              <i class="kt-nav__link-icon mdi mdi-page-layout-header-footer"></i>
                              <span class="kt-nav__link-text">Manage Sub Header</span>
                            </a>
                          </li>

                          <li class="kt-nav__item">
                            <a href="#" @click="clonePage(data.item)" class="kt-nav__link">
                              <i class="kt-nav__link-icon flaticon-file-2"></i>
                              <span class="kt-nav__link-text">Clone</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link" @click="delele(data.item.id)">
                              <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                              <span class="kt-nav__link-text">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="kt-widget__toolbar" v-else>
                      <a
                        href="#"
                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                        data-toggle="dropdown"
                      >
                        <i class="flaticon-more-1"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                        <ul class="kt-nav">
                          <li class="kt-nav__item">
                            <a href="#" @click="recoverPage(data.item.id)" class="kt-nav__link">
                              <i class="text-dark fas fa-trash-restore mr-2"></i>
                              <span class="kt-nav__link-text">Recover</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a
                              href="#"
                              @click="deletePermanently(data.item.id)"
                              class="kt-nav__link"
                            >
                              <i class="text-danger fas fa-trash mr-2"></i>
                              <span class="kt-nav__link-text">Delete Permanently</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div class="col-md-12 text-right">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="pull-right"
                  ></b-pagination>
                </div>
              </div>
            </template>
          </KTPortlet>
        </div>
      </div>
      <v-dialog scrollable v-model="pageDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add {{ isBlogPageDialog ? "Blog" : "" }} Page</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      :label="
                        isBlogPageDialog ? 'Blog Page Title' : 'Page Title'
                      "
                      v-model="page.title"
                      required
                      densed
                      outlined
                      @input="slugify(page.title)"
                    ></v-text-field>
                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.page.title.$error"
                    >Page title is required</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Page URL"
                      v-model="page.custom_slug"
                      :prefix="'https://' + siteUrl + '/'"
                      required
                      densed
                      outlined
                    ></v-text-field>
                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.page.custom_slug.$error"
                    >Page url is required</p>
                  </v-col>

                  <v-col cols="12" v-if="pageDialog">
                    <v-select
                      :items="['global', 'yes', 'no']"
                      v-model="page.transparent_header"
                      outlined
                      dense
                      label="Transparent Header Page Setting"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="isBlogPageDialog">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="page.blog_category_id"
                      :items="blogCategories"
                      hide-selected
                      chips
                      required
                      item-text="title"
                      item-value="id"
                      small-chips
                      label="Category"
                      hint="Select a category for this blog page"
                      persistent-hint
                    ></v-autocomplete>
                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.page.blog_category_id.$error"
                    >Blog category is required</p>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-checkbox v-model="page.menuItem" label="Add to menu"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
            <v-btn
              class="btn-save-popup"
              text
              :loading="isBusy"
              @click.prevent="savePage"
            >Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="slugDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Page URL</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      :label="'URL'"
                      v-model="updatePage.custom_slug"
                      :prefix="'https://' + siteUrl + '/'"
                      required
                      densed
                      outlined
                      @input="checkSlug"
                    ></v-text-field>
                    <span class="text-danger" v-if="validUrl"> Url Already Exist Please try other </span>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSlugDialog">Close</v-btn>
            <v-btn
              color="white--text v-btn theme--light elevation-2 v-size--large primary"
              class="btn-save-popup"
              :disabled="!validUrl"
              :loading="isBusy"
              @click.prevent="updateCustomSlug"
            >Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog scrollable v-model="subHeaderDialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Sub Header Settings</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-switch v-model="pageSubHeader.show_sub_header" label="Show Sub Header"></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-switch
                  v-model="pageSubHeader.sub_header_background_text_inverse"
                  label="Inverse Color"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  hint="Upload background image"
                  persistent-hint
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  label="Background Image"
                  v-model="pageSubHeader.sub_header_background"
                  outlined
                ></v-file-input>
                <div id="favIconPreview">
                  <img
                    style="max-height: 200px"
                    v-if="pageSubHeader.sub_header_background_path"
                    :src="pageSubHeader.sub_header_background_path.thumb"
                  />
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  v-model="pageSubHeader.sub_header_background_size"
                  :items="['cover', 'contain']"
                  outlined
                  label="Background Size"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  v-model="
                    pageSubHeader.sub_header_background_horizontal_position
                  "
                  :items="['left', 'center', 'right']"
                  outlined
                  label="Background Position (Horizontal)"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  v-model="pageSubHeader.sub_header_background_text_position"
                  :items="['left', 'center', 'right']"
                  outlined
                  label="Text Position "
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  v-model="
                    pageSubHeader.sub_header_background_vertical_position
                  "
                  :items="['top', 'bottom']"
                  outlined
                  label="Background Position (Vertical)"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  v-model="pageSubHeader.sub_header_background_height"
                  label="Background Height"
                  suffix="px"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  v-model="pageSubHeader.sub_header_background_color"
                  hide-input
                  label="Background Color"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                  <verte menuPosition="top" model="hex"  v-model="pageSubHeader.sub_header_background_color">
                    <svg viewBox="0 0 24 24">
                      <path d="M0 20h24v4H0z" />
                      <path
                          style="fill: #000"
                          d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                      />
                    </svg>
                  </verte>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSubHeaderDialog()">Cancel</v-btn>
            <v-btn  :loading="isBusy" class="btn btn-primary" @click="updateSubHeader">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { required } from "vuelidate/lib/validators";
import Website from "@/services/Websites/WebsiteService";
import WebsitePage from "@/services/Websites/WebsitePageService";
import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";

const WebsiteService = new Website();
const WebsitePageService = new WebsitePage();
const WebsiteBlogCategoryService = new WebsiteBlogCategory();

export default {
  name: "website-pages",
  components: {
    KTPortlet
  },

  validations() {
    if (this.isBlogPageDialog) {
      return {
        page: {
          title: {
            required
          },
          custom_slug: {
            required
          },
          blog_category_id: {
            required
          }
        }
      };
    } else {
      return {
        page: {
          title: {
            required
          },
          custom_slug: {
            required
          }
        }
      };
    }
  },

  data() {
    return {
      isBusy: false,
      loading: true,
      slugDialog: false,
      subHeaderDialog: false,
      isBlogPageDialog: false,
      blogCategories: [],
      pageDialog: false,
      sortBy: "title",
      sortDesc: false,
      seoDialog: false,
      validUrl: false,
      activePages: 0,
      trashPages: 0,
      allPages: 0,
      type: "default",
      updatePage: {
        id: null,
        custom_slug: null
      },
      rules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!"
      ],
      url: null,
      website: null,
      fields: [
        {
          key: "title",
          sortable: true
        },
        {
          key: "seo_ready",
          label: "SEO Status",
          sortable: false
        },
        {
          key: "is_active",
          label: "Published ",
          sortable: false
        },
        {
          key: "action",
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      page: {
        title: "",
        custom_slug: "",
        menuItem: 0,
        blog_category_id: "",
        transparent_header: ""
      },
      pageSubHeader: {
        show_sub_header: 1,
        sub_header_background_text_inverse: 1
      },
      seo: {
        seo_title: "",
        seo_description: ""
      },
      seoSettingPage: null,
      pages: []
    };
  },
  methods: {
    getWebsite() {
      WebsiteService.get(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
    showDialog() {
      this.isBlogPageDialog = false;
      this.pageDialog = true;
    },
    showBlogDialog() {
      this.isBlogPageDialog = true;
      this.pageDialog = true;
    },
    closeDialog() {
      this.pageDialog = false;
      this.$v.$reset();
      this.isBlogPageDialog = false;
    },
    showSeoDialog() {
      this.seoDialog = true;
    },
    openSubHeaderDialog() {
      this.subHeaderDialog = true;
    },
    closeSubHeaderDialog() {
      this.subHeaderDialog = false;
    },
    closeSeoDialog() {
      this.seoDialog = false;
      this.seoSettingPage = null;
    },
    getBlogCategory() {
      WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
        this.blogCategories = res.data.data;
      });
    },
    subheader(item) {
      delete item.social_share_image;
      this.pageSubHeader = item;
      (this.pageSubHeader.sub_header_background = null),
        this.openSubHeaderDialog();
    },
    delele(id) {
      this.$confirm({
        message: `Are you sure you want to permanently remove this item? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsitePageService.delete(this.siteUrl, id).then(res => {
              this.$snotify.success("Page deleted");
              this.getPages();
            });
          }
        }
      });
    },
    savePage() {
      this.$v.$touch();
      if (!this.$v.page.$error) {
        this.isBusy = true;
        WebsitePageService.create(this.siteUrl, this.page).then(res => {
          this.pageDialog = false;
          this.isBusy = false;
          this.page = {};
          this.$v.$reset();
          this.$snotify.success("Page added");
          this.getPages();
        });
      }
    },
    slugify(text, ampersand = "and") {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    imageChange(e) {
      this.url = URL.createObjectURL(e);
    },
    checkSlug(){
      WebsitePageService.getPageBySlug(this.website.id,this.updatePage.custom_slug).then(response=>{
        this.validUrl = response.data;
      }).catch(error=>{
        // console.log(error)
      }).finally(()=>{
      })
    },
    getPages(type = "default", flag = null) {
      this.loading = true;
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.loading = false;
        this.pages = res.data.pages;
        this.activePages = res.data.active;
        this.trashPages = res.data.trash;
        this.allPages = res.data.all;
      });
    },
    editPage(id) {
      if (this.website.is_gridless_builder == 1) {
        let url =
          process.env.VUE_APP_SERVER_URL +
          "pagebuilder/" +
          this.siteUrl +
          "/website/editor/gridless/" +
          id +
          "#builder";
        window.open(url, "_blank");
      } else {
        let url =
          process.env.VUE_APP_SERVER_URL +
          "pagebuilder/" +
          this.siteUrl +
          "/website/editor/" +
          id +
          "/update#pagebuilder";
        window.open(url, "_blank");
      }
    },
    editPageSlug(page) {
      this.slugDialog = true;
      this.updatePage.id = page.id;
      this.updatePage.custom_slug = page.custom_slug;
      // this.checkSlug()
    },
    closeSlugDialog() {
      this.slugDialog = false;
    },
    updateCustomSlug() {
      this.isBusy = true;
      let data = {
        custom_slug: this.updatePage.custom_slug
      };
      WebsitePageService.update(this.siteUrl, this.updatePage.id, data).then(
        res => {
          this.isBusy = false;
          this.updatePage = {};
          this.closeSlugDialog();
          this.$snotify.success("Page url updated");
          this.getPages();
        }
      );
    },
    clonePage(page) {
      this.$confirm({
        message: `Are you sure you want to clone this item? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.loading = true;
            WebsitePageService.clone(this.siteUrl, page.id)
              .then(response => {
                this.loading = false;
                this.$snotify.success("Page cloned");
                this.getPages();
              })
              .catch(error => {});
          }
        }
      });
    },
    seoSetting(id) {
      this.seoSettingPage = id;
      this.showSeoDialog();
    },
    updateSeoSetting() {
      WebsitePageService.update(
        this.siteUrl,
        this.seoSettingPage,
        this.seo
      ).then(res => {
        this.closeSeoDialog();
        this.$snotify.success("SEO settings updated");
        this.getPages();
      });
    },
    updateSubHeader() {
      this.isBusy = true;
      let fd = new FormData();
      for (var key in this.pageSubHeader) {
        if (
          key === "social_share_image" &&
          (this.pageSubHeader["social_share_image"] == null ||
            this.pageSubHeader["social_share_image"] == undefined)
        ) {
        }
        if (
          key === "sub_header_background" &&
          (this.pageSubHeader["sub_header_background"] == null ||
            this.pageSubHeader["sub_header_background"] == undefined)
        ) {
        } else {
          fd.append(
            key,
            this.pageSubHeader[key] == "1"
              ? "1"
              : this.pageSubHeader[key] == "null"
              ? ""
              : this.pageSubHeader[key]
          );
        }
      }

      WebsitePageService.update(this.siteUrl, this.pageSubHeader.id, fd).then(
        res => {
          this.isBusy = false;
          this.closeSubHeaderDialog();
          this.$snotify.success("Subheader settings updated");
          this.getPages();
        }
      );
    },
    slugify(text) {
      this.page.custom_slug = text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    recoverPage(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsitePageService.recoverPage(this.siteUrl, id)
              .then(response => {
                this.$snotify.success("Page restored");
                this.getPages();
              })
              .catch(error => {})
              .finally(() => (this.isBusy = false));
          }
        }
      });
    },
    deletePermanently(id) {
      this.$confirm({
        message: `Are you sure you want to permanently remove this item? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsitePageService.deletePermanently(this.siteUrl, id)
              .then(response => {
                this.$snotify.success("Page deleted");
                this.getPages();
              })
              .catch(error => {})
              .finally(() => (this.isBusy = false));
          }
        }
      });
    }, reImportPages(){
      this.$confirm({
        message: `Are you sure you want to re-import pages? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsitePageService.reImportPages(this.siteUrl)
                .then(response => {
                  this.$snotify.success("Request has been processed");
                  this.getPages();
                })
                .catch(error => {
                  this.$snotify.error("Something went wrong")
                })
                .finally(() => (this.isBusy = false));
          }}});
    }
  },
  computed: {
    rows() {
      return this.pages.length;
    },
    siteUrl() {
      return this.$route.params.domainname;
    },
    generateSlug() {
      return this.slugify(this.page.title);
    },
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Manage Pages",
        route: "/websites"
      }
    ]);
    this.getPages();
    this.getBlogCategory();
    this.getWebsite();
  }
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 350px;
}
</style>
